import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const aa = [{
    "id": "0",
    "type": "MFTPO___2___Yearly spend ($M/year),Average project size ($M)",
    "options": [
        "North America",
        "South America",
        "Eastern Europe",
        "Western Europe",
        "Middle East",
        "Africa",
        "Eurasia (CIS / Russia)",
        "East Asia (China, Japan, Taiwan, South Korea)",
        "Southeast Asia",
        "Oceania (Australia)",
        "Other (please specify)"
    ],
    "parent": [],
    "parentOptions": {},
    "title": "1.1 In which geographies is your company primarily operating?",
},
    {
    "id": "0",
    "type": "RBO___2___Leading,Lagging",
    "options": [
        "North America",
        "South America",
        "Eastern Europe",
        "Western Europe",
        "Middle East",
        "Africa",
        "Eurasia (CIS / Russia)",
        "East Asia (China, Japan, Taiwan, South Korea)",
        "Southeast Asia",
        "Oceania (Australia)",
        "Other (please specify)"
    ],
    "parent": [],
    "parentOptions": {},
    "title": "1.1 In which geographies is your company primarily operating?",
},
    {
        "id": "0",
        "type": "Rate",
        "options": [
            "North America",
            "South America",
            "Eastern Europe",
            "Western Europe",
            "Middle East",
            "Africa",
            "Eurasia (CIS / Russia)",
            "East Asia (China, Japan, Taiwan, South Korea)",
            "Southeast Asia",
            "Oceania (Australia)",
            "Other (please specify)"
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
    },
    {
        "id": "0",
        "type": "SSFQ",
        "options": [
            "North America",
            "South America",
            "Eastern Europe",
            "Western Europe",
            "Middle East",
            "Africa",
            "Eurasia (CIS / Russia)",
            "East Asia (China, Japan, Taiwan, South Korea)",
            "Southeast Asia",
            "Oceania (Australia)",
            "Other (please specify)"
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
    },
    {
        "id": "0",
        "type": "RR",
        "options": [
            "North America",
            "South America",
            "Eastern Europe",
            "Western Europe",
            "Middle East",
            "Africa",
            "Eurasia (CIS / Russia)",
            "East Asia (China, Japan, Taiwan, South Korea)",
            "Southeast Asia",
            "Oceania (Australia)",
            "Other (please specify)"
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
    },
    {
        "id": "0",
        "type": "MFTPO___2___Yearly spend ($M/year),Average project size ($M)",
        "options": [
            "North America",
            "South America",
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
        "answer": {
            "selection": [
                "North America",
                "South America",
            ],
            "optionNumber": [["v1", "v2"], ["v3", "v4"]]
        },
    },
    {
        "id": "0",
        "type": "RBO___2___Leading,Lagging",
        "options": [
            "North America",
            "South America",
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
        "answer": {
            "selection": [
                "North America",
                "South America",
            ],
            "optionNumber": ["Leading", "Leading"]
        },
    },
    {
        "id": "0",
        "type": "Rate",
        "options": [
            "North America",
            "South America",
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?",
        "answer": {
            "selection": [
                "North America",
                "South America",
            ],
            "optionNumber": [3, 5]
        },
    }]
const devResponse = {
    data: {
        Result:  {
            "pillar": "Design & Engineering",
            "pillar_id": 3,
            "segments": [{
                "id": 1,
                "questions": [
                    {
                        "id": "0",
                        "type": "MS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia)",
                            "Other (please specify)"
                        ],
                        "parent": [],
                        "parentOptions": {},
                        "title": "1.1 In which geographies is your company primarily operating?",
                        "answer": {
                            "selection": [
                                "North America",
                                "Western Europe",
                            ],
                            "optionNumber": [0, 3]
                        },

                    },
                    {
                        "id": "1",
                        "type": "SS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                            "Other (please specify)"
                        ],
                        "parent": ["0"],
                        "parentOptions": {
                            "0": [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        "title": "1.1 In which geographies is your company primarily operating?",
                        "answer": {
                            "selection": [
                                "South America",
                                "Western Europe"
                            ],
                            "optionNumber": 5
                        },
                    },
                    {
                        "id": "2",
                        "type": "SS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                            "Other (please specify)"
                        ],
                        "parent": ["1"],
                        "parentOptions": {
                            "1": [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        "title": "1.1 In which geographies is your company primarily operating?",
                        "answer": {
                            "selection": [
                                "South America",
                                "Western Europe"
                            ],
                            "optionNumber": 5
                        },
                    }
                ],
                "status": 0,
                "is_skipped": 0
            }]
        }
    }
}

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return {
        pillarName: respResult.pillar,
        pillarId: respResult.pillar_id,
        segmentId: _.get(respResult.segments, "[0].id"),
        questions: _.get(respResult.segments, "[0].questions"),
        isSkipped: respResult.is_skipped,
        status: respResult.status

    }
}

export default async (pillarId, segmentId)=>{
    let url = "pillar_segment";
    if (pillarId && segmentId){
        url = `${url}?pillar_id=${pillarId}&segment=${segmentId}`
    }
    const response = await pillarApi.getData(url).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}