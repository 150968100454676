<template>
  <div id="app">
    <router-view></router-view> <!-- Route components are rendered here -->
  </div>
</template>

<script>

export default {
  components: {
  }
};
</script>

<style>
.app-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  background: #8737E1;
  height: 51px;
  color: #333333;
  width: 81px;
}

</style>