import { createApp } from 'vue';
import App from './App.vue';
// import store from './store';
import router from './router'; // Import the router
import easySpinner from 'vue-easy-spinner';

import "./assets/css/tailwind.css";
import "./assets/css/global.css";

createApp(App)
    .use(router) // Use the router instance
    .use(easySpinner, {
        prefix: 'easy',
    })
    .mount('#app');

// createApp(App).use(store).use(router).mount("#app");
