import { createRouter, createWebHistory } from "vue-router";
// import DashboardLayout from '@/layouts/DashboardLayout.vue';
// import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import HomeView from "../views/Home/HomeView.vue";
// import AdminDashboardView from '@/views/Timesheet/Admin/AdminDashboardView.vue';
// import UserDashboardView from '@/views/Timesheet/User/UserDashboardView.vue';
// import HistoryView from '@/views/Timesheet/Admin/HistoryView.vue';
import LoginView from '@/views/LoginView.vue'
// import KearneyAssistView from '@/views/Tools/KearneyAssistView.vue'
// import RavenAIView from '@/views/Tools/RavenAIView.vue'
import Landing from '@/components/Landing.vue';
// import Pillar from '@/components/Pillar.vue';
import SurveyQA from "@/views/SurveyQA.vue";

const routes = [
  {
    path: '/',
    redirect: '/pillar'
  },
  {
    path: '/pillar',
    name: "SurveyDashboard",
    component: SurveyQA,
  },
  {
    path: '/login',
    name: "login",
    component: LoginView
  },
  {
    path: '/landing',
    name: "landing",
    component: Landing
  },
  // {
  //   path: '/admin',
  //   component: DashboardLayout,
  //   children: [
  //     {
  //       path: "",
  //       name: "admindashboard",
  //       component: AdminDashboardView,
  //     },
  //     {

  //       path: "history",
  //       name: "adminhistory",
  //       component: HistoryView,
  //     }
  //   ]
  // },
//   {
//     path: '/user',
//     component: DashboardLayout,
//     children: [
//       {

//         path: "",
//         name: "userdashboard",
//         component: UserDashboardView,
//       }
//     ]
//   },
//   {
//     path: '/tools',
//     component: DashboardLayout,
//     children: [
//       {

//         path: "kearney-assist",
//         name: "kearneyassist",
//         component: KearneyAssistView,
//       },
//       {
//         path: "raven",
//         name: "raven",
//         component: RavenAIView,
//       }
//     ]
//   }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
