<script>
import { ref } from "vue";
import { pca } from "@/config/authConfig.js";
import axios from "axios";
import { useRouter } from 'vue-router';
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Loader
  },
  setup() {
    const loading = ref(false);
    const profilePicture = ref(null);
    const router = useRouter(); // Get router instance


    const signIn = async () => {
      const loginRequest = {
        scopes: ["User.Read", "openid", "profile", "email"],
      };

      try {
        await pca.initialize();
        const response = await pca.loginPopup(loginRequest);
        localStorage.setItem('accessToken', String(response['accessToken']));
        console.log('response: ', response);
        console.log(response['accessToken'], "response['access_token']");
        // TODO: create an api to return the role of user from BE
        if (response['accessToken']) {
          let userData = {
            firstName: response.account.name.split(" ")[1].trim(),
            lastName: response.account.name.split(" ")[0].trim(),
            email: response['account']['username']
          };
          loading.value = true;
          fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/user-information`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
              .then((res) => {
                return res.json()
              })
              .then((data) => {
                if (data && data?.Result) {
                  userData['role'] = data?.Result
                  localStorage.setItem("userData", JSON.stringify(userData));
                  router.push({ name: 'landing' });
                } else {
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                loading.value = false;
              });

        }
      } catch (error) {
        alert("Authentication failed!");
        console.error("Authentication error:", error);
      }
    };

    return { signIn, loading, profilePicture };
  },
};
</script>
<template>
  <div class="flex items-center h-screen">
    <!-- Left half with an image -->
     <Loader :loading="loading" :add-margin-for-left-nav="false" />
    <div class="w-1/2 h-screen relative flex justify-center items-center">
      <img :src="require('@/assets/img/kearney-logo-black.svg')" class="p-3" alt="logo"
           style="position: absolute; top: 10px; left: 10px;" />
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-3xl">Sign In</h2>
        <p class="text-md text-gray-500 my-5">
          Welcome back! Please Sign in to your account.
        </p>
        <button v-ripple @click="signIn" class="bg-black text-white px-20 py-3 rounded-lg mt-5">
          Sign In using Kearney SSO
        </button>
        <div class="w-2/3 text-justify text-xs mt-10">
          By clicking on 'Sign In', you confirm to have read the 'Solution Factory
          Privacy Statement' and provide consent to use your personal
          information like Name and Email ID.
        </div>
      </div>
    </div>

    <div class="w-1/2 h-screen relative">
      <img :src="require('@/assets/img/landing.png')" alt="Image" class="w-full h-screen object-cover" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="lvp-intro">
          <div class="lvp-intro-headline">ExCap Survey</div>
          <div class="lvp-intro-sub-headline">Your Voice, Our Vision—Shape the Future with Every Survey!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.privacy-statement {
  color: #a0186a;
  text-decoration: underline;
}

.lvp-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
  width: 464px;
  text-align: center;
}

.lvp-intro-headline {
  font-size: 60px;
  font-weight: 800;
  line-height: 72px;
}

.lvp-intro-sub-headline {
  font-size: 32px;
  font-weight: 400;
  line-height: 51px;
}
</style>