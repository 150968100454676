<script>
import _ from "lodash";
import getStatus from "@/services/survey/getStatus";
import TopStrip, {TOP_STRIP_MODES} from "@/components/TopStrip.vue";
import ExcapPyramidImage from "@/assets/img/excap_pyramid.png";

export default {
  components: {
    TopStrip
  },
  data() {
    return {
      bgImg: require('@/assets/img/bg.png'),
      insights: require('@/assets/img/Insights.svg'),
      logo: require('@/assets/img/kearney-logo-black.svg'),
      dropdownOpen: false,
      isAuthenticated: !!localStorage.getItem('accessToken'), // Initial check for token
      pillarInfo: [],
      TOP_STRIP_MODES,
      ExcapPyramidImage,
      _: _
    }
  },

  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: 'calc(100vh - 68px)',
        padding: '20px',
      }
    },
    getUserToken() {
      return localStorage.getItem('accessToken');
    },
    categories(){
      return _.map(this.pillarInfo, v => {
        return {
          'category': v.pillarName,
          'count': _.reduce(v.segments, (acc, v1)=>{
            acc += _.toNumber(v1.static_questions);
            return acc;
          }, 0)
        }
      })
    }
  },

  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    logout() {
      localStorage.clear(); // Clear local storage
      this.isAuthenticated = false; // Update reactive property
      this.$router.push({name: "home"}); // Redirect to home page
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    navigate() {
      this.$router.push({name: "SurveyDashboard"});
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  watch: {
    getUserToken(newValue) {
      this.isAuthenticated = !!newValue;
    }
  },
  async created() {
    // await getStatus().then((res)=>{
    //   if (res && !res.error){
    //     this.pillarInfo = res;
    //   }
    // })
  }
}
</script>
<template>
  <div class="top-strip">
    <TopStrip :mode="TOP_STRIP_MODES.PAGE"/>
  </div>
  <div :style="backgroundStyle" class="page-wrapper">
    <div class="landing-content">
      <div class="topContainer">
        <div class="text-white font-avenir text-4xl font-bold leading-[72px] pb-4">IV<sup>th</sup> ExCap Survey</div>
        <div class="survey-intro-text text-white text-xl leading-[26px]">
          Gain valuable insights into the latest capital project management trends and see how your company measures up against the best
        </div>
        <div class="flex mt-3">
          <div class="text-white text-xl leading-[26px]">
            Time to Complete:
          </div>
          <div class="text-white font-avenir text-xl font-bold pl-1">~45 minutes</div>
        </div>
        <div>
          <button @click="navigate" class="surveyBtn">Take the survey now</button>
        </div>
      </div>
      <div class="landing-img" :style="`background-image: url(${ExcapPyramidImage});`">
      </div>
    </div>

<!--    <div class="bottomContainer flex mt-10 flex-wrap">-->
<!--      <div class="box" v-for="(option, idx) in categories" :key="idx">-->
<!--        <img :src="insights"/>-->
<!--        <p>{{ option.category }}</p>-->
<!--        <p>Total Questions - {{ option.count }}</p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="confidentiality-statement">
      <div class="confidentiality-statement-header">
        CONFIDENTIALITY STATEMENT:
      </div>
      <div class="confidentiality-statement-sub">
        Your individual responses and results will be confidential.
      </div>
    </div>
  </div>
</template>
<style>
.bottomContainer {
  padding: 20px;
  border-radius: 24px;
  background: linear-gradient(124deg, rgba(0, 0, 0, 0.36) 5.12%, rgba(0, 0, 0, 0.07) 102.99%);
  backdrop-filter: blur(4px);
}

.landing-content {
  display: flex;
}
.landing-img {
  height: 35rem;
  width: 39rem;
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: absolute;
  right: 60px;
  top: 100px;
}
.topContainer {
  display: flex;
  width: 985px;
  height: 348px;
  padding: 30px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 24px;
  background: linear-gradient(124deg, rgba(0, 0, 0, 0.36) 5.12%, rgba(0, 0, 0, 0.07) 102.99%);
  backdrop-filter: blur(4px);
}

.box {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.80);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
  backdrop-filter: blur(4px);
  min-width: 200px;
  padding: 20px;
  margin: 7px;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  color: #fff;
  text-align: left;
}

.surveyBtn {
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(99, 225, 225, 0.25);
  color: #8737E1;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  background-color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  padding: 24px 48px;
  align-items: flex-start;
  gap: 10px;
}
.survey-intro-text{
  width: 600px;
}
.boxPillar {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.80);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
  backdrop-filter: blur(4px);
}
.confidentiality-statement {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  left: 50px;
}

.confidentiality-statement-header {
  font-weight: 700;
  font-size: 20px;
}

.confidentiality-statement-sub {
  font-weight: 150;
  font-size: 20px;
}
</style>
