<script>
import _ from "lodash";
import numberToWords from "number-to-words";
import GreenTickIcon from "@/assets/img/green-tick.svg";
import { CircleProgressBar } from 'circle-progress.vue';

const COLOR_PALLETE = ["#55A8B5", "#E5102A", "#96CC00", "#EA7B03", "#8737E1", "#F1E6FE", "#2c9f4d"]

export default {
  name: "SurveyCompleted",
  components: {CircleProgressBar},
  props: {
    pillarInfo: {
      type: Object,
      required: true
    },
    handleNavigatePillar: {
      type: Function,
      required: true
    }
  },
  data(){
    return {
      GreenTickIcon,
      numberToWords,
      _: _
    }
  },
  computed: {
    progressData(){
      return _.reduce(this.pillarInfo, (acc, v, i)=> {
        const completedSegments = _.filter(v.segments, segment => segment.status === 1)
        acc.push({
          name: v.pillarName,
          total: _.reduce(v.segments, (acc1, segment)=> acc1 + segment.static_questions + 2, 0),
          completed: _.reduce(completedSegments, (acc1, segment)=> acc1 + segment.static_questions + 2, 0),
          color: COLOR_PALLETE[i % _.size(COLOR_PALLETE)]
        })
        return acc;
      }, [])
    },
    skippingData(){
      const toReturn = _.reduce(this.pillarInfo, (acc, v)=> {
        const skipped = _.filter(v.segments, segment => segment.is_skipped === 1);
        if (_.size(skipped) > 0) {
          acc.push({
            name: v.pillarName,
            skipped: _.size(skipped),
            total: _.size(v.segments)
          })
        }
        return acc;
      }, []);
      return toReturn;
    }
  }
}
</script>

<template>
  <div class="sco-container">
    <div class="sco-thanks-container">
      <div class="sco-thanks-sub-container">
        <div class="sco-icon-container">
          <img style="width: 54px; height: 54px" :src="GreenTickIcon" alt=""/>
        </div>
        <div class="sco-thank-message">Thanks for Sharing.</div>
        <div class="sco-thank-sub-message">Your Answers have been successfully submitted.</div>
      </div>
    </div>

    <div class="sco-skipping-info-section">
      <div v-for="sd in skippingData" :key="sd.name">
        <div class="sco-skipping-info">
          <div class="sco-skipping-info-line1">
            Skipped <span class="sco-skipping-info-line1-part">{{_.capitalize(numberToWords.toWords(sd.skipped))}} part{{sd.skipped > 1 ? 's' : ''}}</span> of <span  class="sco-skipping-info-line1-pillar">{{sd.name}}</span>
          </div>
          <div class="sco-skipping-info-line2">
            Kindly fill the questions by visiting the
            <span
                class="sco-skipping-info-line2-link"
                @click="()=>{
                  handleNavigatePillar(sd.name)
                }"
            >Link
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-6 mt-6 gap-3">
      <div class="sco-circular-progress-container" :style="`border: 3px solid ${pd.color}`" v-for="pd in progressData" :key="pd.name">
        <CircleProgressBar
            class="sco-circular-progress-widget"
            :value="pd.completed"
            :max="pd.total"
            :colorBack="pd.color"
            :color-filled="pd.color"
            color-unfilled="#efefef"
            strokeWidth="10px"
        >
          <div class="sco-circular-progress-info-container">
            <div class="sco-circular-progress-info-pillarname">
              {{pd.name}}
            </div>
            <div class="sco-circular-progress-info-pillarprogress-container">
              <span :style="`color: ${pd.color}`" class="sco-circular-progress-info-pillarprogress-completed">{{pd.completed}}</span>
              <span :style="`color: ${pd.color}`" class="sco-circular-progress-info-pillarprogress-total"> / {{pd.total}}</span>
            </div>
          </div>
        </CircleProgressBar>
      </div>
    </div>

  </div>
</template>

<style scoped>
.sco-container{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.sco-thanks-container{
  border-radius: 20px;
  background-color: #F1E6FE;
  margin: 10px 100px;
}
.sco-thanks-sub-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 100px;
}
.sco-icon-container{
  margin-bottom: 50px;
}
.sco-thank-message{
  font-size: 28px;
  font-weight: 700;
  color: #8737E1;
  margin-bottom: 10px;
}

.sco-thank-sub-message{
  font-size: 21px;
  font-weight: 400;
}
.sco-skipping-info{
  text-align: center;
  margin-top: 10px;
}
.sco-skipping-info-line1-part{
  font-weight: 700;
}

.sco-skipping-info-line1-pillar{
  font-weight: 700;
  color: #8737E1;
}
.sco-skipping-info-line2-link{
  font-weight: 700;
  color: #8737E1;
  text-decoration: underline;
  cursor: pointer;
}
.sco-circular-progress-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-self: center;
}
.sco-circular-progress-container{
  border-radius: 14px;
  padding: 5px;
}
.sco-circular-progress-info-container{
  text-align: center;
}
.sco-circular-progress-info-pillarname {
  font-weight: 700;
  font-size: 10px;
  text-overflow: ellipsis;
  width: 78px;
  white-space: nowrap;
  overflow: hidden;
}

.sco-circular-progress-info-pillarprogress-completed {
  font-size: 28px;
  font-weight: 600;
}

.sco-circular-progress-info-pillarprogress-total {
  font-size: 12px;
  font-weight: 600;
}
</style>